require('@popperjs/core')
import { Tooltip, Collapse } from 'bootstrap'
import 'unpoly/dist/unpoly'
import SimpleLightbox from 'simple-lightbox'

up.compiler('body', () => {
    $('html').removeClass('no-js')
    up.feedback.config.currentClasses.push('active');

    new SimpleLightbox({elements: 'a.js-lightbox'});
})

up.compiler('.js-tooltip', (element) => {
    let tooltip = new Tooltip(element, {html: true})

    return () => tooltip.dispose()
})

up.compiler('#js-navbar-toggle', (element) => {
    element.addEventListener('click', () => {
        element.classList.toggle('open')
        document.querySelector('.offcanvas-collapse').classList.toggle('open')
    })
})
